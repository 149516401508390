import { getComponentGroupCode } from "../utilityFunctions/getComponentGroupName"
import { getProfileFromLanguage } from "../utilityFunctions/getProfile"
import { ArmrestsPosition, getArmrestsPosition } from "../utilityFunctions/sofaShapeUtils"


const getProductName = (product) => {
    return product?.advanced_product?.advanced_product_profile.name.split(' ')[0]
  }
  
  export const SITTING_CUSHION_DESIGN_CODE = 'sitting-cushion-design'
  export const BACK_CUSHION_DESIGN_CODE = 'back-cushion-design'
  export const SITTING_CUSHION_COMFORT_CODE = 'sitting-cushion-comfort'
  export const BACK_CUSHION_COMFORT_CODE = 'back-cushion-comfort'
  export const ARMREST_CODE = 'armrest'
  export const LEGS_CODE = 'legs'
  export const THREADS_CODE = 'threads-type'
  export const THREADS_COLOR_CODE = 'threads-colors'
  export const LOGOS_CODE = 'logos'
  export const SHOOTING_CODE = 'shooting'
  export const DIRECTION_CODE = 'direction'

const getSingleComponentConfigString = (product,    fabrics,
    additionalComponents,
    fabricCombination,setId, itemId, item, isMiddleFrame) => {
    const productName = getProductName(product)

    const withGroupNames = additionalComponents.map((component) => ({
      ...component,
      groupCode: getComponentGroupCode(
        product.advanced_product.additional_component_groups,
        component.additionalComponentGroupId,
      ),
    }))

    // find group with name porankiai
    let armRestLeft = ''
    let armRestRight = ''

    const armrests = additionalComponents?.find((c) => c.groupCode === ARMREST_CODE + '-' + item.name)
  
    let armrestConfig = ''
    if (armrests) {
      armRestLeft =
        getArmrestsPosition(item.type) === ArmrestsPosition.LEFT ||
        getArmrestsPosition(item.type) === ArmrestsPosition.BOTH
          ? armrests.code.split(':')[1] + 'X1' // replace last symbol with 1 (left armrest)
          : ''

      armRestRight =
        getArmrestsPosition(item.type) === ArmrestsPosition.RIGHT ||
        getArmrestsPosition(item.type) === ArmrestsPosition.BOTH
          ? armrests.code.split(':')[1] + 'X2' // replace last symbol with 2 (right armrest)
          : ''
        
        armrestConfig = `${armRestLeft}${armRestRight}`
    } 

    // baldam turintiems abu porankius (baigiasi penketu) reikia paduoti abu defaultinius porankiu id
    if(armrestConfig.length === 0) {
      if(item.code?.[item.code?.length -1] === '5'){
         armrestConfig = 'AXXXXXAXXXXX'
      } else {
        armrestConfig = 'AXXXXX'
      }
    }

        // get armrest design from metadata
        let frameDesign = 'XX'
        try {
          frameDesign = JSON.parse(armrests?.metadata ?? '{}')?.frame_design ?? 'XX'
        } catch (e) {
          console.error('Error parsing armrest metadata', e)
        }

        

    const getSelectedComponent = (groupCode: string) =>
      withGroupNames.find((group) => group.groupCode === groupCode)

    const getSelectedComponentMulti = (groupCode: string) =>
      withGroupNames.filter((group) => group.groupCode.startsWith(groupCode))

    const getSelectedCode = (groupCode: string) =>
      getSelectedComponent(groupCode)?.code


    let leg = (getSelectedCode(LEGS_CODE) ?? '000')

    // if module code ends with 0, then it is a middle frame (corner, seater, etc.)
    if(item.code?.endsWith('0') && item.metadata.legs?.length > 0){
      console.log('middle frame', item)

      let legComponent = getSelectedComponent(LEGS_CODE)
      // find conponents name
      const legName = getProfileFromLanguage(legComponent?.additional_component_profiles, 'lt')?.name 
      let additionalLeg;
      if(legName) {
        console.log('legName', legName)

        const nameParts = legName.split(' ')
        const legHeight = nameParts[0]
        const legNumber = nameParts[1]
        
        // cut off two first elements and join the rest
        const legColor = nameParts.slice(2).join(' ')

        //filter out module legs that are same height and color
        const filteredLegs = item.metadata.legs.filter((l) => l.legHeight === legHeight && l.legColor === legColor)

        
        if(filteredLegs.length == 1){
          additionalLeg = filteredLegs[0].code
        } else  {
          // look for leg that has same legNumber as metadata
          const legWithCode = filteredLegs.find((l) => l.legNumber === legNumber)
          if(legWithCode){
            additionalLeg = legWithCode.code
          }
        }
        
        
        // Case when module shoud not have any legs or there some default value, then use that default value
        // This also covers case for mudules like B075X0 which shoudl get 000 as a leg code.
        if(!additionalLeg && item.metadata.legs.length === 1) {
          const defaultLeg = item.metadata.legs[0]
            additionalLeg = defaultLeg.code
        }

        console.log('data', {module: item.code, legHeight, legNumber, legColor, filteredLegs, leg, additionalLeg, moduleLegs: item.metadata.legs})

        leg = additionalLeg ?? leg
      }

    }

    // get logo name
    let logoComponent = getSelectedComponent(LOGOS_CODE)
    const logoName = getProfileFromLanguage(logoComponent?.additional_component_profiles, 'lt')?.name 


    //get thread colors // we need at least two colors. event they are 0|0
    const threads = getSelectedComponentMulti(THREADS_COLOR_CODE )?.map((thread) => thread.code)
    if(threads.length === 0){
      threads.push('0')
      threads.push('0')
    }
    if(threads.length === 1){
      threads.push('0')
    }
  
    const c = {
      setId,
      itemId,
      //category: 51,
      /// these values are hardcoded
      price: 100,
      discount: 0,
      quantity: 1,
      requestedShipDate: '2025-01-01',
      /// ---------------------
      armrestConfig,
      productName,
      moduleCode: item.code,
      moduleName: item.name,
      shapeType: item.type,
      armrestsPosition: getArmrestsPosition(item.type),
      armRestLeft,
      armRestRight,
      leg,
      frameDesign,
      isMiddleFrame,
      sittingCushionDesign: getSelectedCode(SITTING_CUSHION_DESIGN_CODE),
      backCushionDesign: getSelectedCode(BACK_CUSHION_DESIGN_CODE),
      sittingCushionComfort: getSelectedCode(SITTING_CUSHION_COMFORT_CODE),
      backCushionComfort: getSelectedCode(BACK_CUSHION_COMFORT_CODE),
      threads: threads,
      threads_color: getSelectedCode(THREADS_COLOR_CODE),
      logo: getSelectedCode(LOGOS_CODE),
      logoName,
      shooting: getSelectedCode(SHOOTING_CODE),
      fabric: fabrics?.fabricGroupObject?.code,
      //fabrics_raw: fabrics,

      fabricColor: fabrics?.fabricObject?.code,
      direction: getSelectedCode(DIRECTION_CODE),
      //fabricsRaw: fabrics,
      fabrics: Object.values(fabrics.combinationFabrics ?? {})
        .map((f: any) => ({
          position: f.option.code,
          fabric: f.fabricGroupObject.code,
          fabricColor: f.fabricObject.code,
        }))
        .sort((a, b) => a.position.localeCompare(b.position)),

      fabricCombination: fabricCombination?.code,
    }

    console.log("Config", c)

    let fabricColors = ''
    c.fabrics.map((f, i) => {
      fabricColors += `${f.fabric}-${f.fabricColor}`
      if (i < c.fabrics.length - 1) {
        fabricColors += '|'
      }
    })


    const configuration =
      `${c.armrestConfig}` +
      `.${c.sittingCushionDesign}${c.sittingCushionComfort}.${c.backCushionDesign}${c.backCushionComfort}` +
      `.${c.frameDesign}.${c.shooting}.${c.fabricCombination}.${fabricColors}.${c.threads.join('|')}` +
      `.${c.logo}.${c.leg}.${c.direction}`
    return { ...c, configuration }
}

export const getConfigString = ({
    product,
    sofaCombinations,
    fabrics,
    additionalComponents,
    fabricCombination,
    advancedProductTotalPricesPerModule
  }) => {
   
    const sets = []
    // Going through all combinations and creating list (sets) if list (items). Each item is a configuration string
    const setId = 0
    for (const [i, combination = []] of sofaCombinations.entries() ?? []) {
      const newSet = []
      const mudulesCount = Object.entries(combination).length
      let index = 0
      for (const [j, shape] of Object.entries(combination)) {
       
        const itemId = parseInt(j) + 1
        const item = (shape as any)?.attrs?.originalSofaForm 

        let isMiddleFrame = false
        if(itemId > 1 && itemId < mudulesCount){
            isMiddleFrame = true
        }
       // console.log('item>>>>', item.code)

        const modulePrice = advancedProductTotalPricesPerModule?.find((p) => p.shape === item.code)?.price


        const config = getSingleComponentConfigString(product,    fabrics,
            additionalComponents,
            fabricCombination, setId, itemId, item, isMiddleFrame)

        newSet.push({...config, 
          price:modulePrice,
        })
      }
      sets.push(newSet)
    }
    return sets
  }