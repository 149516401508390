import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  advancedProductTotalPriceVar,
  advancedProductTotalPricesPerModuleVar,
  cartStateVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
  selectedSofaCombinationsVar,
} from '../../../../store/reactiveVars'
import { checkDiscountValidity } from '../../../../utilityFunctions/discountValidityCheck'
import { priceFormatter } from '../../../../utilityFunctions/priceUtils'
import { useTranslation } from 'next-i18next'
import { Media } from '../../../../../lib/artsyFresnel/Media'
import { addToCart } from '../../../../operations/clientMutations/cartClient'
import { useSnackbarContext } from '../../Utils'
import { flatten } from '../../../../utilityFunctions/parseSofaCombination'
import useWindowDimensions from '../../Utils/WindowDimensionsHook'
import { imageFallback } from '../../../../constants/Images'
import QuantitySelectorVertical from '../../Utils/QuantitySelectorVertical'
import QuantitySelectorHorizontal from '../../Utils/QuantitySelectorHorizontal'
import { ProductPrice } from '../../../UtilityComponents/price'
import { useAppContext } from '../../../../context/appContext'
import { getConfigString } from '../../../../intefrationUtils/vilmers'
import { getArmrestOverides } from './SofaDrawingElements/utils'
import { TENANT_VILMERS } from '../../../../constants/featureFlags'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      // ----
      root: {
        // border: '1px solid red',
        width: '100%',
        // padding: '20px',
        // position: '-webkit-sticky' /* Safari */,
        // position: 'sticky',
        bottom: 0,
        zIndex: 4,
        // background: '#fff',
        borderTop: '1px solid #e2e1e0',
      },

      priceBox: {
        height: '100px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        borderLeft: '1px solid #e2e1e0',
        [theme.breakpoints.down('lg')]: {
          height: '60px',
          alignItems: 'flex-end',
        },
      },

      priceString: {
        marginRight: '100px',
        [theme.breakpoints.down('lg')]: {
          marginRight: '10px',
        },
      },

      // Price info
      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      buttonBox: {
        display: 'flex',
        padding: '20px',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          padding: '0px',
        },
      },

      button: {
        height: '60px',
        [theme.breakpoints.down('lg')]: {
          height: '100%',
        },
      },
    }),
  { name: 'MuiStickyPricePreview' },
)

const StickyPricePreview = ({
  discount,
  viewingStep,
  finalStep,
  steps,
  advancedProduct,
  productPermalink,
  ...props
}) => {
  // -----
  // console.log('sofaForm :>> ', sofaForm)
  const classes = useStyles()
  const advancedProductTotalPrice = useReactiveVar(advancedProductTotalPriceVar)
  const advancedProductTotalPricesPerModule = useReactiveVar(
    advancedProductTotalPricesPerModuleVar,
  )
  const { discounted, discountedPrice } = checkDiscountValidity(
    discount,
    advancedProductTotalPrice,
  )

  const appContext = useAppContext()
  const pricesDisabled = appContext.pricesDisabled

  const advancedProductType =
    advancedProduct?.advanced_product?.advanced_product_type

  let priceExists = advancedProductTotalPrice > 0 ? true : false

  if (process.env.NEXT_PUBLIC_TENANT === TENANT_VILMERS) {
    priceExists = true
  }

  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const applyResize = windowHeight < 900 ? true : false

  // const [applyResize, setApplyResize] = useState(
  //   windowHeight < 900 ? true : false,
  // )

  // useEffect(() => {
  //   // --- Recheck the height agian
  //   const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  //   setApplyResize(windowHeight < 900 ? true : false)
  // }, [])

  const { t, i18n } = useTranslation(['common'])
  const totalString = t('total')
  const addToCartCtaButton = t('add-to-cart-cta-button')
  const nonCumulativeCouponWhenAddingProduct = t(
    'non-cumulative-coupon-when-adding-product',
  )

  const { snackbar, setSnackbar } = useSnackbarContext()

  // ADVANCED PRODUC DATA ======
  const selectedSofaCombinations = useReactiveVar(selectedSofaCombinationsVar)
  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )
  const selectedAddtionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )
  const { fabricCombination } = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )

  const additonalComponentGroups =
    advancedProduct.advanced_product.additional_component_groups ?? null

  // ===== DIMENSIONS ======
  let currentDimensions = {
    width: advancedProduct?.advanced_product?.dimensions?.width ?? null,
    length: advancedProduct?.advanced_product?.dimensions?.length ?? null,
    height: advancedProduct?.advanced_product?.dimensions?.height ?? null,
    radius: advancedProduct?.advanced_product?.dimensions?.radius ?? null,
    weight: advancedProduct?.advanced_product?.dimensions?.weight ?? null,
    //--- CHAIR
    seat_height:
      advancedProduct?.advanced_product?.dimensions?.seat_height ?? null,
    seat_depth:
      advancedProduct?.advanced_product?.dimensions?.seat_depth ?? null,
    seat_width:
      advancedProduct?.advanced_product?.dimensions?.seat_width ?? null,
    armrest_height:
      advancedProduct?.advanced_product?.dimensions?.armrest_height ?? null,
    // ---- BED
    headboard_height:
      advancedProduct?.advanced_product?.dimensions?.headboard_height ?? null,
    headboard_width:
      advancedProduct?.advanced_product?.dimensions?.headboard_width ?? null,
    mattress_width:
      advancedProduct?.advanced_product?.dimensions?.mattress_width ?? null,
    mattress_length:
      advancedProduct?.advanced_product?.dimensions?.mattress_length ?? null,
    mattress_height:
      advancedProduct?.advanced_product?.dimensions?.mattress_height ?? null,
    mattress_overlay_height:
      advancedProduct?.advanced_product?.dimensions?.mattress_overlay_height ??
      null,
    lying_part_height:
      advancedProduct?.advanced_product?.dimensions?.lying_part_height ?? null,
    bedbox_height:
      advancedProduct?.advanced_product?.dimensions?.bedbox_height ?? null,
    bedbox_width:
      advancedProduct?.advanced_product?.dimensions?.bedbox_width ?? null,
    bedbox_length:
      advancedProduct?.advanced_product?.dimensions?.bedbox_length ?? null,
    frame_thickness:
      advancedProduct?.advanced_product?.dimensions?.frame_thickness ?? null,
    //---- TABLE
    table_extended_lengh:
      advancedProduct?.advanced_product?.dimensions?.table_extended_lengh ??
      null,
    table_top_thickness:
      advancedProduct?.advanced_product?.dimensions?.table_top_thickness ??
      null,
    table_leg_width:
      advancedProduct?.advanced_product?.dimensions?.table_leg_width ?? null,
    //--- LGIHTS
    shade_height:
      advancedProduct?.advanced_product?.dimensions?.shade_height ?? null,
    shade_radius:
      advancedProduct?.advanced_product?.dimensions?.shade_radius ?? null,
    //---MISC
    leg_height:
      advancedProduct?.advanced_product?.dimensions?.leg_height ?? null,
  }
  // ======================================
  // OVERWRITE DIMENSIONS FROM SELECTED COMPONENTS HERE!
  for (const selectedComponent of selectedAddtionalComponents) {
    //1) Find the group of selected component
    if (selectedComponent?.additionalComponentGroupId) {
      const selectedGroupID = selectedComponent?.additionalComponentGroupId
      const selectedGroup =
        advancedProduct.advanced_product.additional_component_groups.filter(
          (item) => item.id === selectedGroupID,
        ) ?? []
      if (selectedGroup.length) {
        if (
          selectedGroup[0].enable_custom_dimensions_for_components &&
          selectedComponent?.dimensions
        ) {
          const dimensionNamesToOverwrite =
            selectedGroup[0].enabled_dimensions_in_group
          for (const dimensionNameToOverwrite of dimensionNamesToOverwrite) {
            //-- Apply overwrites on --> currentDimensions
            currentDimensions[dimensionNameToOverwrite] =
              selectedComponent?.dimensions[dimensionNameToOverwrite] ?? null
          }
        }
      }
    }
  }

  // if sofa has armrests with difrent width, then the originalSofaForm.dimensions.armrest_width must be updated
  // this is needed to draw correct armrests in the cartpage, of in the order page

  const armrestWidthOverrideArr = getArmrestOverides(
    selectedAddtionalComponents,
  )

  const withArmrestWidth = selectedSofaCombinations.map((combination) => {
    return combination.map((item) => {
      const clone = item.clone()
      const armrestWidthOverride = armrestWidthOverrideArr.find(
        (m) => m.moduleId === clone.attrs.id,
      )
      clone.attrs.new_armrest_width = armrestWidthOverride?.armrestWidth
      return clone
    })
  })

  const selectedSofaCombinationsJSON = JSON.stringify(withArmrestWidth)

  const productImages = advancedProduct.advanced_product.images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

  const cartImage =
    productImages[0]?.src_xs ?? productImages[0]?.src ?? imageFallback

  const [quantity, setQuantity] = useState(1)

  return (
    <Paper
      elevation={2}
      className={classes.root}
      style={{ position: applyResize ? 'relative' : 'sticky' }}
    >
      <Grid container justifyContent="space-evenly" spacing={0}>
        <Grid item xs={0} lg={7}>
          <Media greaterThan="md">
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Stepper activeStep={viewingStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Media>
        </Grid>

        <Grid item xs={7} lg={3}>
          {priceExists && !pricesDisabled && (
            <>
              <Box className={classes.priceBox}>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.priceString}
                >
                  {totalString}
                </Typography>

                <div className={classes.pricesContainer}>
                  <Typography
                    // fontSize={12}
                    variant={discounted ? 'body1' : 'body2'}
                    component="p"
                    className={clsx(discounted && classes.discoutedPrice)}
                  >
                    <ProductPrice
                      price={advancedProductTotalPrice * quantity}
                    />
                  </Typography>
                  {discounted && (
                    <Typography variant="subtitle1" component="p">
                      {' '}
                      {discountedPrice && (
                        <ProductPrice price={discountedPrice * quantity} />
                      )}
                    </Typography>
                  )}
                </div>

                <Media greaterThan="md">
                  {advancedProductType != 'SOFA' ? (
                    <QuantitySelectorVertical
                      className=""
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                  ) : null}
                </Media>
              </Box>
            </>
          )}
        </Grid>

        <Grid item xs={5} lg={2} className={classes.buttonBox}>
          {!pricesDisabled && (
            <Button
              className={classes.button}
              variant="contained"
              // disabled={!finalStep || !priceExists}
              disabled={!priceExists}
              // disabled={productDisabled || loading}
              onClick={() => {
                console.log(
                  'Pressing (ADVANCED PRODUCT) configure...',
                  advancedProduct,
                )
                addToCart(
                  {
                    // --
                    // We need a unique ID so we could increase qunaitity
                    id: Date.now(),
                    name: advancedProduct.advanced_product
                      .advanced_product_profile.name,
                    price: advancedProductTotalPrice,
                    prices_per_module: advancedProductTotalPricesPerModule,
                    sku: null,
                    image: cartImage,
                    quantity: quantity,
                    permalink: productPermalink,
                    product_type: advancedProduct.type,
                    dispatchInformation: advancedProduct.dispatch_information,
                    discount: advancedProduct.discount,

                    // Additional ADVANCED PRODUCT DATA =====
                    advanced_product_data: {
                      advanced_product_type:
                        advancedProduct.advanced_product.advanced_product_type,
                      selected_fabric: selectedAdvancedProductFabric,
                      selected_sofa_combinations: selectedSofaCombinationsJSON,
                      selected_additional_components:
                        selectedAddtionalComponents,
                      additional_component_groups: additonalComponentGroups,
                      discount: advancedProduct.discount,
                      dimensions: currentDimensions,
                      selected_fabric_combination: fabricCombination,
                    },
                    product_container_id: advancedProduct.id,
                    minimum_order_quantity: null,
                    integration_configuration: getConfigString({
                      product: advancedProduct,
                      sofaCombinations: withArmrestWidth,
                      fabrics: selectedAdvancedProductFabric,
                      additionalComponents: selectedAddtionalComponents,
                      fabricCombination,
                      advancedProductTotalPricesPerModule,
                    }),
                  },
                  { snackbar, setSnackbar },
                  { nonCumulativeCouponWhenAddingProduct },
                )
                cartStateVar(true)
              }}
            >
              {addToCartCtaButton}
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default StickyPricePreview
